<template>
  <a-spin :spinning="loading" :tip="loadingTip">
    <div class="tableList">
      <div class="search">
        <div class="search-body">
          <a-form-model layout="inline">
            <a-form-model-item label="部室">
              <a-cascader
                :options="orgTree"
                v-model="search.orgId"
                :fieldNames="{
                  label: 'name',
                  value: 'id',
                  children: 'children',
                }"
                placeholder="请选择部室"
              />
            </a-form-model-item>
            <a-form-model-item label="标题">
              <a-input v-model="search.title" placeholder="请输入" />
            </a-form-model-item>
            <a-form-model-item label="状态">
              <a-select
                v-model="search.status"
                placeholder="请选择"
                allowClear
                style="width: 120px"
              >
                <a-select-option :value="0">待提交</a-select-option>
                <a-select-option :value="1">已提交</a-select-option>
                <a-select-option :value="2">通过</a-select-option>
                <a-select-option :value="3">不通过</a-select-option>
              </a-select>
            </a-form-model-item>
            <a-form-model-item label="日期">
              <a-range-picker @change="onTimeChange" />
            </a-form-model-item>
            <a-form-model-item>
              <p-button type="primary" name="搜索" @click="searchList" />
            </a-form-model-item>
            <a-form-model-item style="float: right">
              <a-button
                type="primary"
                style="margin-right: 10px"
                @click="addOrUpdate(null)"
                >新增</a-button
              >
              <a-button type="default" @click="exportData">导出</a-button>
            </a-form-model-item>
          </a-form-model>
        </div>
      </div>
      <div class="tableItem">
        <a-table
          rowKey="id"
          :columns="tableInfo.columns"
          :data-source="tableInfo.datas"
          :pagination="tableInfo.pagination"
          bordered
          @change="onChange"
          :locale="{ emptyText: '暂无数据' }"
          :scroll="{ y: tableInfo.tableHeight }"
        >
          <!-- <span slot="action" slot-scope="action">
            <a-button
              @click="addOrUpdate(action)"
              size="small"
              type="primary"
              v-if="action.status == 0"
              >修改</a-button
            >

            <a-popconfirm
              title="提交后数据不可更改，是否确认？"
              @confirm="changeStatus(action.id, 1)"
              v-if="action.status == 0"
            >
              <a-button size="small" type="danger">提交</a-button>
            </a-popconfirm>
            <a-button
              @click="addOrUpdate(action)"
              size="small"
              type="primary"
              v-if="
                action.processExecuteStatus && action.taskKey == '其他-审批'
              "
              >审批</a-button
            >

            <a-popconfirm
              title="确定要删除记录吗？"
              @confirm="deleteItem(action.id)"
              v-if="action.status == 0"
            >
              <a-button size="small" type="danger">删除</a-button>
            </a-popconfirm>
          </span> -->
        </a-table>
      </div>
      <a-modal
        :title="editor.title"
        v-model="editor.visible"
        width="90%"
        :footer="false"
        destroyOnClose
        :maskClosable="false"
      >
        <createdocumentprint :info="editor.info" @callback="modelCallBack" />
      </a-modal>
    </div>
  </a-spin>
</template>

<script>
import documentprintApi from "@/api/work/documentprint";
import createdocumentprint from "./createdocumentprint.vue";
import orgApi from "@/api/org";
import workflowApi from "@/api/workflow";
export default {
  name: "documentprint",
  data() {
    return {
      loading: false,
      loadingTip: "加载中",
      search: {
        itemName: "",
        orgId: [],
        status: null,
      },
      tableInfo: {
        datas: [],
        columns: [
          {
            key: "index",
            align: "center",
            title: "序号",
            width: 80,
            customRender: this.showText,
          },
          {
            dataIndex: "orgName",
            align: "center",
            title: "部室",
            customRender: this.showText,
          },
          {
            dataIndex: "time",
            align: "center",
            title: "日期",
            customRender: this.showText,
          },
          {
            dataIndex: "handlerName",
            align: "center",
            title: "经办人",
            customRender: this.showText,
          },
          // {
          //   dataIndex: "title",
          //   align: "center",
          //   title: "文印标题",
          // },
          // {
          //   dataIndex: "count",
          //   align: "center",
          //   title: "数量",
          // },
          // {
          //   dataIndex: "remark",
          //   align: "center",
          //   title: "备注",
          // },
          {
            key: "status",
            align: "center",
            title: "状态",
            customRender: this.showText,
          },
          {
            title: "操作",
            key: "action",
            align: "center",
            customRender: (text, record, index) => {
              var obj = {
                // attrs: {
                //   // rowSpan:
                //   //   record.itemId == record.items[0].id
                //   //     ? record.items.length
                //   //     : 0,
                // },
              };

              if (
                (record.status == 0 || record.status == 3) &&
                record.creator ==
                  localStorage.getItem(this.$commons.User.userName)
              ) {
                obj.children = (
                  <span>
                    <a-button
                      vOn:click={() => this.addOrUpdate(record)}
                      size="small"
                      type="primary"
                    >
                      修改
                    </a-button>
                    <a-popconfirm
                      title="提交后数据不可更改，是否确认？"
                      vOn:confirm={() => this.changeStatus(record.id, 1)}
                    >
                      <a-button size="small" type="danger">
                        提交
                      </a-button>
                    </a-popconfirm>
                    <a-popconfirm
                      title="确定要删除吗？"
                      vOn:confirm={() => this.deleteItem(record.id)}
                    >
                      <a-button size="small" type="danger">
                        删除
                      </a-button>
                    </a-popconfirm>
                  </span>
                );
              } else if (record.processExecuteStatus) {
                if (record.taskKey == "其他-验收") {
                  obj.children = (
                    <a-button
                      vOn:click={() => this.addOrUpdate(record)}
                      size="small"
                      type="primary"
                    >
                      验收
                    </a-button>
                  );
                } else {
                  obj.children = (
                    <a-button
                      vOn:click={() => this.addOrUpdate(record)}
                      size="small"
                      type="primary"
                    >
                      审批
                    </a-button>
                  );
                }
              } else if (record.status == 1 || record.status == 2) {
                obj.children = (
                  <a-button
                    vOn:click={() => this.addOrUpdate(record)}
                    size="small"
                    type="default"
                  >
                    详情
                  </a-button>
                );
              }

              return obj;
            },
          },
        ],
        pagination: {
          showQuickJumper: true,
          hideOnSinglePage: false,
          showSizeChanger: true,
          total: 0,
          defaultCurrent: 1,
          current: 1,
          defaultPageSize: 10,
          showTotal: (total) => `总共${total}条`,
        },
      },
      selectRows: [],
      editor: {
        title: "编辑",
        visible: false,
        info: {},
      },

      orgTree: [],
    };
  },
  components: {
    createdocumentprint,
  },
  mounted() {
    this.getOrgTree();
    this.getPageList();
  },
  methods: {
    acceptData(record) {
      var audit = {
        taskKey: record.taskKey,
        taskId: record.taskId,
        businessId: record.id,
        businessType: record.processModelKey,
        auditResult: 1,
      };
      workflowApi.postAuditData(audit).then((res) => {
        if (res.errorCode === this.$commons.RespCode.success) {
          this.$message.success("操作成功");
          this.getPageList();
        } else {
          this.$message.error(res.errorMsg);
        }
      });
    },
    showText(text, record, index, column) {
      let s = text;
      if (column.key && column.key == "index") {
        s =
          (this.tableInfo.pagination.current - 1) *
            this.tableInfo.pagination.defaultPageSize +
          index +
          1;
      } else if (column.key && column.key == "status") {
        s = <processStatus processModel={record} />;
      }
      return {
        children: s,
        // attrs: {
        //   rowSpan:
        //     record.itemId == record.items[0].id ? record.items.length : 0,
        // },
      };
    },
    getOrgTree() {
      orgApi.getTree(true, false).then((res) => {
        if (res.errorCode == this.$commons.RespCode.success) {
          this.orgTree = res.data;
        }
      });
    },
    deleteItem(id) {
      this.loading = true;
      documentprintApi.deleteItem(id).then((res) => {
        this.loading = false;
        if (res.errorCode === this.$commons.RespCode.success) {
          this.getPageList();
        } else {
          this.$message.error(res.errorMsg);
        }
      });
    },
    changeStatus(id, status) {
      this.loading = true;
      documentprintApi.changeStatus(id, status).then((res) => {
        this.loading = false;
        if (res.errorCode === this.$commons.RespCode.success) {
          this.getPageList();
        } else {
          this.$message.error(res.errorMsg);
        }
      });
    },
    buildData(datas) {
      var list = [];
      var rowIndex = 0;
      datas.forEach((item) => {
        let items = item.items;
        rowIndex++;
        if (items.length == 0) {
          item.rowIndex = rowIndex;
          list.push(item);
          return;
        }

        for (var i = 0; i < items.length; ++i) {
          list.push({
            rowIndex: i == 0 ? rowIndex : null,
            id: i == 0 ? item.id : item.id + "-" + i,
            orgName: item.orgName,
            time: item.time,
            handlerName: item.handlerName,
            title: items[i].title,
            count: items[i].count,
            remark: items[i].remark,
            status: item.status,
            acceptData: item.acceptData,
            acceptUserName: item.acceptUserName,
            rowSpan: i == 0 ? items.length : 0,
            processExecuteStatus: item.processExecuteStatus,
            processId: item.processId,
            processModelKey: item.processModelKey,
            processStatus: item.processStatus,
            taskId: item.taskId,
            taskKey: item.taskKey,
            taskRoles: item.taskRoles,
            taskUsers: item.taskUsers,
            currentNodeName: item.currentNodeName,
            currentNode: item.currentNode,
          });
        }
      });
      return list;
    },
    exportData() {
      var _this = this;
      _this.loading = true;
      documentprintApi.exportData({ ...this.search }, (d) => {
        _this.loadingTip = "已完成" + d + "%";
        if (d == 100) {
          setTimeout(function () {
            _this.loading = false;
          }, 2000);
        }
      });
    },
    async getPageList() {
      let _this = this;
      let param = {
        ...this.search,
        pageNow: _this.tableInfo.pagination.current,
        pageSize: _this.tableInfo.pagination.defaultPageSize,
      };
      param.orgId = param.orgId ? param.orgId[param.orgId.length - 1] : null;
      documentprintApi.getList(param).then((res) => {
        if (res.errorCode === _this.$commons.RespCode.success) {
          // _this.tableInfo.datas = this.buildData(res.data.records);
          _this.tableInfo.datas = res.data.records;
          _this.tableInfo.pagination.total = Number(res.data.total);
        } else {
          this.$message.error(res.errorMsg);
        }
      });
    },
    onTimeChange(date, dateString) {
      this.search.acceptStartDate = dateString[0];
      this.search.acceptEndDate = dateString[1];
    },
    onChange(page) {
      let _this = this;
      _this.search.pageNow = page.current;
      _this.search.pageSize = page.pageSize;
      _this.tableInfo.pagination.current = page.current;
      _this.tableInfo.pagination.defaultPageSize = page.pageSize;
      _this.getPageList();
    },
    searchList() {
      this.getPageList();
    },

    addOrUpdate(info) {
      let _this = this;
      _this.editor.visible = true;
      if (info) {
        _this.editor.title = "编辑";
        if (info.status == 1 || info.status == 2) {
          _this.editor.title = "查看";
        }
      } else {
        _this.editor.title = "新增";
      }
      _this.editor.info = info;
    },
    showList(info) {
      //打开弹出框
      let _this = this;
      _this.itemListInfo.visible = true;
      _this.itemListInfo.title = "字典子集";
      _this.itemListInfo.info = info;
    },
    modelCallBack() {
      this.editor.visible = false;
      this.getPageList();
    },
  },
};
</script>

<style lang="less">
</style>
